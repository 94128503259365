footer{
    margin: 0;
    padding: 2em 0;
    width: 100%;
    background-color: rgb(7, 7, 7);
    color:rgb(231, 231, 231);
    border-top: 3px solid red;
    /*box-shadow: 0px 0px 25px 7px red;*/
}

footer a{
    padding: 0 2em;
}

#cr {
    color: grey;
    margin: 1.5em 0;
    font-size: 80%;
    font-style: italic;
    font-weight: bold;
    text-align: center;
}

#wcb {
    padding: 0 2em;
    float: right;
}