.submenu {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
}

.submenu-item {
    height: 2em;
    border-left: ghostwhite solid 1.5px;
    border-right: ghostwhite solid 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(27,27,27);
    transition: background-color 250ms ease-in-out;
}

.submenu-item:hover {
    background-color: #474747;
}

.submenu-item.first {
    border-left-width: 3px;
  }
  
.submenu-item.last {
    border-right-width: 3px;
}

.submenu-item.active {
    background-color: #474747;
}

.submenu p {
    margin: 0;
    font-size: 150%;
    font-weight: 600;
}