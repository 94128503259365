header {
    max-width: 100%;
    margin-bottom: 3rem;
    margin-left: 4rem;
}

header h1 {
    font-size: 5.5rem;
    font-weight: 900;
    background: -webkit-linear-gradient(96deg, rgb(255, 0, 0) 14%, rgb(255, 0, 40) 30%, rgb(255, 0, 90) 44%, rgb(255, 0, 140) 66%, rgb(255, 0, 190) 82%, rgb(255, 0, 255) 94%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2rem;
}

header h2 {
    font-size: 1.5rem;
    margin-left: calc(10% - 4rem);
}

nav {
    width: 80%;
    margin-inline: 10%;
    display: flex;
    flex-direction: column;
}

nav a {
    text-decoration: none;
    color: rgb(255, 75, 246);
}

.navNum {
    color: rgb(188, 0, 178);
    margin-left: 0;
    margin-right: 6px;
}

article {
    max-width: 100%;
    margin-top: 0;
    margin-left: 1.5rem;
    color: white;
}

article div {
    margin-inline: 10%;
}

article h2 {
    font-size: 3rem;
    text-decoration: underline;
}

article h3 {
    font-size: 2.5rem;
}

article h4 {
    font-size: 1.5rem;
}

@media screen and (min-height: 1200px) {
    header {
        margin-left: 1.5rem;
    }

    header h1 {
        font-size: 8.5rem;
    }

    nav a {
        font-size: 3rem;
    }

    article {
        margin-left: 0;
    }

    article h2 {
        font-size: 6rem;
    }

    article h3 {
        font-size: 5rem;
    }

    article h4 {
        font-size: 3rem;
    }
}