@font-face {
  font-family: "Neuropolitical";
  src: url(neuropolitical_rg.ttf);
  font-display: swap;
}

.nav-container {
  margin-bottom: 3.5em;
  width: 100%;
}

.nav {
  overflow: hidden;
  background-color: rgb(19, 19, 19);
  height: 3.5em;
	position: fixed;
  top: 0;
  width: 100%;
  /*box-shadow: 0px 0px 25px 7px red;*/
  z-index: 1;
}

.nav_logo {
  float: left;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height: 100%;
}

.nav_links{
  float: right;
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
  margin-right: 1.5em;
}

.nav a, .nav p {
  color: red;
  text-align: center;
}

.nav .nav_links a {
  padding: 0 0.7em;
  text-decoration: none;
  font-size: 1.2em;
}

.nav p {
  float: left;
  font-family: "Neuropolitical";
  font-size: 2.1em;
  margin: 0;
}

.nav img {
  float: left;
  height: 4em;
  padding-right: 0.5em;
  padding-left: 1em;
}

/*
Background removed
background-color: rgb(35, 35, 35);
*/
.nav a:not(.icon):hover {
    color: rgb(205,0,0);
}

/*
Background removed
background-color: rgb(35, 35, 35);
*/
.nav a.active {
    color: red;
}

/*
Background removed
background-color: rgb(19, 19, 19);
*/
.nav .icon {
  display: none;
} 

@media screen and (max-width: 980px) {
  .nav .nav_logo p {
    display: none;
  }
}

/*@media screen and (max-width: 980px) {
  .nav a:not(.icon) {
    display: none;
  }
  .nav a.icon {
    float :right;
    display: block;
  }
}

@media screen and (max-width: 980px) {
  .nav.responsive {position: relative; overflow:visible}
  .nav.responsive a.icon {
    float: right;
    left: 0;
    top: 0;
  }
  .nav.responsive a:not(.icon) {
    float: none;
    display: block;
    text-align: right;
    width: calc(100% - 64px);
    padding: 14px 32px;
  }
  .nav.responsive .nav_links {
    flex-direction: column;
  }
  .nav.responsive a {
    align-self: flex-end;
    background-color: rgb(19, 19, 19);
  }
}*/