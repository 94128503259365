.contact-container {
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
    color: white;
}

.contact-type {
    width: 30%;
}

.social {
    width: 4em;
    height: 4em;
}

.social img {
    height: 100%;
    width: 100%;
}