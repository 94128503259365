.chat-content {
    color: ghostwhite !important;
    padding: 0 2em !important;
}

.chat-avatar {
    border-radius: 50%;
    height: 7vh;
    margin-right: .8em;
}

.chat-user {
    justify-content: flex-end;
    font-size: 1.5em;
    align-items: center;
    height: 8vh;
    padding-top: 2vh;
}

.chat-messages {
    height: 90vh;
    overflow-y: scroll;
    justify-content: start !important;
}

.chat-spacer {
    height: 10vh;
    min-height: 10vh;
}

.chat-message {
    border: solid .1em gray;
    border-radius: 1em;
    width: 40%;
    justify-items: center;
    margin-bottom: 2em;
}

.chat-msg-own {
    align-self: flex-end;
}

.chat-msg-own .chat-message-user {
    justify-content: flex-end;
}

.chat-msg-own .chat-timestamp {
    justify-content: flex-start;
}

.chat-msg-own .chat-upper {
    flex-direction: row !important;
}

.chat-msg-other {
    align-self: flex-start;
}

.chat-msg-other .chat-message-user {
    justify-content: flex-start;
}

.chat-msg-other .chat-timestamp {
    justify-content: flex-end;
}

.chat-msg-other .chat-upper {
    flex-direction: row-reverse !important;
}

.chat-upper {
    padding: .5em 0;
    border-bottom: solid .01em;
    width: 95%;
}

.chat-timestamp {
    align-items: center;
    width: 50%;
}

.chat-message-timestamp {
    color: grey;
}

.chat-message-user {
    align-items: center;
    width: 50%;
}

.chat-msg-user-avatar {
    height: 2.5em;
    border-radius: 50%;
    margin-right: 1em;
}

.chat-message-content {
    width: 80%;
    font-size: clamp(0.5em, 0.5em + 1vw, 1.1em);
}

.chat-input-container {
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    margin: 0 2em 0 2em;
}

.chat-input {
    background-color: rgba(60, 60, 60, 0.5);
    width: 85%;
    height: 8vh;
    border: none;
    border-radius: 3em 3em 0 0;
    padding: 0 clamp(1.5em, 1.5em + 1vw, 3.4vw);
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
    color: ghostwhite;
    backdrop-filter: blur(.2em);
}

.chat-inputfield {
    background: transparent;
    width: 85%;
    height: 8vh;
    border: none;
    border-radius: 3em 3em 0 0;
    padding: 0 clamp(1.5em, 1.5em + 1vw, 3.4vw);
    font-size: clamp(0.5em, 0.5em + 2vw, 1.5em);
    color: ghostwhite;
}

.chat-input input {
    width: 95%;
    padding: 0;
}

.chat-input svg {
    width: 5%;
}

.chat-inputfield:focus-visible {
    outline: none;
}

/* Hiding scrollbars where needed */

.chat-messages::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .chat-messages {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }