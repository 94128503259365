.errbtn {
    background-color: rgb(65, 65, 65);
    color: lime;
    border-radius: 12px;
    border: none;
    margin: 25px 0;
    transition-duration: 0.4s;
    display: flex;
    align-items: center;
}

.errbtn:hover,
.errbtn:focus {
    box-shadow: 0 7px 14px -7.5px rgb(65, 65, 65);
    transform: translateY(-0.8em);
}

.errbtn h1 {
    width:fit-content;
    text-align: center;
    margin-top: 0;
    padding: .5em 1em;
}

.content_error {
    color: red;
    padding: 6em 4.1em;
	min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.errdescription {
    color: rgb(231, 231, 231);
    font-size: 92%;
    font-style: italic;
    max-width: 60%;
    width: fit-content;
    background-color: rgb(52,52,52);
    border: solid rgb(41, 41, 41) 5px;
    border-radius: 17px;
    box-shadow: 0px 0px 13px 3px rgb(231, 231, 231);
    padding: 8px 8px;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.errb {
    font-size: 110%;
}

.errh1 {
    width:fit-content;
    font-size: 30px;
}